import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

// components
import Hero from "../../utils/hero";
import Link from "../../utils/link";
import Page from "../../utils/page";
import Section from "../../utils/section";
import theme from "../../utils/theme";

// markup
const CustomizePage = () => {
  return (
    <Page
      lang="en"
      title="Customize your wet wipes and bibs"
      description="In just a few simple steps, you too can have your own customized disposable wet wipes or non-woven bibs with your design. Rely on our team of customization experts to enhance your brand's image."
      type="WebPage"
      readMore={["/contatti"]}
      image="/personalizza-prodotti.jpg"
    >
      <Hero
        title="We are experts in customization"
        description={
          <>
            Rely on our team of customization experts to add an extra touch to
            your brand's image.
            <br />
            Customizing your products may seem like a complicated process, but
            it really isn't!
          </>
        }
        background={theme.palette.primary.main}
        backgroundImage={
          <StaticImage
            style={{ height: "100%" }}
            quality={100}
            src="../../images/backgrounds/fragranza-menta.jpg"
            alt="Mint fragrance"
            layout="fullWidth"
          />
        }
      />

      <Section>
        <Grid container spacing={8}>
          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <Box pb={3}>
                <StaticImage
                  style={{ height: "100%" }}
                  imgStyle={{ borderRadius: 16 }}
                  src="../../images/personalizzazione-salviette-monouso.jpg"
                  alt="Disposable wet wipes: how to customize them"
                  layout="fullWidth"
                />
              </Box>
              <Typography variant="h3" component="h2">
                Disposable wet wipes: how to customize them?
              </Typography>
              <Typography>
                In just a few simple steps, you too can have your own{" "}
                <strong>customized disposable wet wipes</strong>. Here's how:
              </Typography>
              <Stack component="ol" spacing={2}>
                <li>
                  Choose your <strong>favorite fragrance</strong>:
                  <br /> lemon, lime and cinnamon, mojito, green tea, colonial,
                  lavender & chamomile, sweet fruit
                </li>
                <li>
                  Choose the <strong>size</strong>:
                  <br /> standard (10cm x 6.6cm), maxi size (14cm x 7.3cm)
                </li>
                <li>
                  Choose the <strong>paper color</strong>: white or sand
                </li>
                <li>
                  Send us your <strong>logo</strong> to be printed in the
                  designated area on the outer wrapper
                </li>
              </Stack>
              <Box pt={4}>
                <Link type="internal" href="/en/contact" title="Contact us">
                  <Button size="large" variant="contained">
                    Contact us
                  </Button>
                </Link>
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <Box pb={3}>
                <StaticImage
                  style={{ height: "100%" }}
                  imgStyle={{ borderRadius: 16 }}
                  src="../../images/personalizzazione-bavagli-monouso.jpg"
                  alt="Disposable non-woven bibs: how to customize them"
                  layout="fullWidth"
                />
              </Box>
              <Typography variant="h3" component="h2">
                Disposable non-woven bibs: how to customize them?
              </Typography>
              <Typography>
                In just a few simple steps, you too can have your own{" "}
                <strong>customized disposable non-woven bibs</strong>. Here's
                how:
              </Typography>
              <Stack component="ol" spacing={2}>
                <li>
                  Choose the <strong>format</strong>:
                  <br /> standard (h 44.5cm x 37cm), maxi (h 44cm x 40cm), baby
                  (h 26cm x 24cm)
                </li>
                <li>
                  Send us your <strong>logo</strong> to be printed in the
                  designated area on the outer wrapper
                </li>
              </Stack>
              <Box pt={4}>
                <Link type="internal" href="/en/contact" title="Contact us">
                  <Button size="large" variant="contained">
                    Contact us
                  </Button>
                </Link>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Section>
    </Page>
  );
};

export default CustomizePage;
